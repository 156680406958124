import Swal from "sweetalert2";
import NProgress from "nprogress";
import { post } from "../api/general";

export const getDefaultValidationOption = () => {
  return {
    errorElement: "p",
    submitHandler: function (form) {
      NProgress.start();
      enableDisableFormButtons(form, false);
      grecaptcha.ready(() => {
        grecaptcha
          .execute(ajax_object.google_recaptcha_site_key, {
            action: "submit",
          })
          .then((token) => {
            form.querySelector("input[name='recaptcha_token']").value = token;

            post(form)
              .then((response) => {
                form.reset();
                const { message } = response;

                const swalConfig = {
                  title: message.title || "Success!",
                  icon: "success",
                  text: message.text || "Success!",
                };

                let isSwalConfigFound = false;

                import(`../form/swal/${form.name}`)
                  .then((module) => {
                    isSwalConfigFound = true;
                    const customSwalConfig = module.default(form);
                    Swal.fire({
                      ...swalConfig,
                      ...(customSwalConfig?.options ?? {}),
                    }).then((data) => {
                      customSwalConfig?.handleThen &&
                        customSwalConfig.handleThen(data);
                    });
                  })
                  .catch((error) => {
                    console.info(error.message);
                  })
                  .finally(() => {
                    !isSwalConfigFound && Swal.fire(swalConfig);
                  });
              })
              .catch(({ data: res, status }) => {
                const { data } = res;

                if (status == 400) {
                  this.showErrors(data.errors);
                  this.errorList[0].element.focus();
                  return;
                }

                if (status >= 401 && status < 500) {
                  Swal.fire({
                    title: "Validation Error!",
                    text: "validation errors occurred!",
                    icon: "error",
                  });
                  return;
                }
              })
              .finally(() => {
                NProgress.done();
                enableDisableFormButtons(form, true);
              });
          });
      });

      return false;
    },
  };
};

const enableDisableFormButtons = (form, shoudEnable) => {
  const buttons = form.querySelectorAll("button");
  buttons.forEach((button) => {
    if (shoudEnable) {
      button.removeAttribute("disabled");
      button.innerText = button.dataset.text ?? button.innerText;
    } else {
      button.setAttribute("disabled", "disabled");
      button.innerText = button.dataset.textLoading ?? button.innerText;
    }
  });
};

export const handleForm = (form) => {
  if (!form.name) {
    return;
  }

  import(`../form/${form.name}`)
    .then((module) => {
      const object = module.default();
      const validate = $(form).validate(object.option);

      const handleButton = () => {
        const button = form.querySelector('button[type="submit"]');
        const isValid = validate.checkForm();
        button.disabled = !isValid;
      };

      form.addEventListener("keyup", handleButton);
      form.addEventListener("change", handleButton);

      if (typeof object.handleOtherLogic === "function") {
        object.handleOtherLogic(form);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const handleForms = (forms = []) => {
  if (!forms.length) {
    return;
  }
  forms.forEach((form) => {
    handleForm(form);
  });
};
