import axios from "axios";
import Swal from "sweetalert2";

const service = axios.create({
  baseURL: "/wp-admin/",
  timeout: 20000,
});

service.interceptors.request.use(
  (config) => {
    config.headers["X-App-Header"] = "extendmate";
    return config;
  },
  (error) => {
    console.error("error while sending request", error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const res = response.data.data;
    const status = response.status;

    console.log("theres", res);

    if (status >= 200 && status < 300) {
      return res;
    }

    if (status >= 300 && status < 400) {
      console.info("got response to redirect", response);
      return res;
    }

    return Promise.reject(
      new Error(
        res.message ||
          "Error while processing the sucess response in interceptor"
      )
    );
  },
  ({ response }) => {
    const { status } = response;
    const res = response.data;

    if (status >= 500) {
      try {
        const { error } = res.data;
        Swal.fire({
          title: error.title,
          text: error.text,
          icon: "error",
        });
      } catch (error) {
        Swal.fire({
          title: "Server Error!",
          text: "Try again after sometime.",
          icon: "error",
        });
      }
    }

    return Promise.reject(response);
  }
);

export default service;
