import request from "../service/request";

export function post(form) {

  return request({
    url: "admin-ajax.php",
    method: "post",
    data: new FormData(form),
  });
}
