export default function addCustomValidators() {
    $.validator.addMethod(
        "customEmail",
        function (value) {
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
        },
        "Please enter a valid email address"
    );

    $.validator.addMethod(
        "username",
        function (value, element) {
            const regex = /^[a-zA-Z0-9-]+$/;
            return this.optional(element) || regex.test(value);
        },
        "Please enter a valid username"
    );

    $.validator.addMethod(
        "visitorName",
        function (value, element) {
            const fullNameRegex = /^[a-zA-Z\s']+$/;
            return this.optional(element) || fullNameRegex.test(value);
        },
        "Please enter a valid name"
    );
}
