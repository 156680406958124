import "../style/app.css";
require("jquery-validation");

import { handleForms, getDefaultValidationOption } from "../tool/form";
import addCustomValidators from "../tool/validator";

document.addEventListener("DOMContentLoaded", () => {
  $.validator.setDefaults(getDefaultValidationOption());
  addCustomValidators();
  handleForms(document.querySelectorAll("form"));
});

console.log("app.js loaded!");
